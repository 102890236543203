import HelperService from '@/services/HelperService';
import axios from '@/libs/axios';

const baseUrl = '/scribe-jobs'

export default {
  list(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  get(id) {
    return axios.get(`${baseUrl}/${id}`)
  },
  delete(id) {
    return axios.delete(`${baseUrl}/${id}`)
  },
  create(scribeJob) {
    return axios.post(`${baseUrl}`, scribeJob)
  },
  upload(id, file) {
    const formData = new FormData()
    formData.append('file', file)

    return axios.post(`${baseUrl}/${id}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  uploadV2(id, file) {
    const formData = new FormData()
    formData.append('file', file)

    return axios.post(`${baseUrl}/${id}/upload/v2`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

  },
  update(id, jobDto) {
    return axios.put(`${baseUrl}/${id}`, jobDto)
  },
  updateSpeaker(id, label) {
    return axios.put(`scribe-speakers/${id}`, {speaker_label: label})
  },
  getAudioFile(id) {
    return axios.get(`${baseUrl}/${id}/file`, {responseType: "blob"})
  },
  generateSummary(jobID, summaryType) {
    return axios.post(`${baseUrl}/${jobID}/summary`, {summary_type: summaryType})
  },
  updateTranscript(id, dto) {
    return axios.put(`/scribe-transcriptions/${id}`, dto)
  },
  updateSummary(id, dto) {
    return axios.put(`/scribe-summaries/${id}`, dto)
  },
  getCreatedJobs(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/stats/created${queryString}`);
  },
  getSessionInfo(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/stats/session-info${queryString}`);
  },
  getJobTypes() {
    return axios.get(`/scribe/job-types`);
  },
  getSummaryTypes() {
    return axios.get(`/scribe/summary-types`)
  }
}
